import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import styles from "./article.module.css"
import Metadata from "../components/metadata"

import "katex/dist/katex.min.css"

function ArticleTemplate({ data }) {
  const { contentfulArticle } = data
  const { title, rawDate, prettyDate, body } = contentfulArticle
  const { description } = contentfulArticle.description
  const { childMarkdownRemark } = body
  const { html } = childMarkdownRemark
  const tags = contentfulArticle.tags || []

  let authors = []
  if (contentfulArticle.authors) {
    authors = contentfulArticle.authors.map(author => author.name)
  }

  return (
    <>
      <Metadata
        pageTitle={title}
        pageDescription={description}
        keywords={tags}
        authors={authors}
      />
      <Layout>
        <article className={styles.container}>
          <header>
            <h1>{title}</h1>
            <div className={styles.headerInfo}>
              Written by {authors.join("& ")} on{" "}
              <time id="pubdate" dateTime={rawDate}>
                {prettyDate}
              </time>
            </div>
          </header>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <footer>
            <address>
              You can reach me at{" "}
              <a href="mailto:toni@karpton.com">toni@karpton.com</a> if you have
              questions about this article.
            </address>
          </footer>
        </article>
      </Layout>
    </>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulArticle: PropTypes.shape({
      title: PropTypes.string.isRequired,
      rawDate: PropTypes.string.isRequired,
      body: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query Article($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      title
      description {
        description
      }
      rawDate: createdAt(formatString: "YYYY-MM-DD HH:mm")
      prettyDate: createdAt(formatString: "MMMM DD, YYYY")
      tags
      authors {
        name
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default ArticleTemplate
